document.addEventListener("DOMContentLoaded", function(){
  AOS.init();

  var imJs = {
      m: function (e) {
          imJs.d();
          imJs.methods();
      },
      d: function (e) {
          this._window = $(window),
          this._document = $(document),
          this._body = $('body'),
          this._html = $('html')
      },
      methods: function (e) {
        imJs.metismenu();
        imJs.sideMenu();
        imJs.stickySidebar();
        // imJs.searchOpton();
      },
      // metismenu
      metismenu:function(){
        $('#mobile-menu-active').metisMenu();
      },
       // side menu desktop
       sideMenu:function(){
        $(document).on('click', '.menu-btn', function () {
          console.log('---->')
          $("#side-bar").addClass("show");
          $("#anywhere-home").addClass("bgshow");
        });
        $(document).on('click', '.close-icon-menu', function () {
          $("#side-bar").removeClass("show");
          $("#anywhere-home").removeClass("bgshow");
        });
        $(document).on('click', '#anywhere-home', function () {
          $("#side-bar").removeClass("show");
          $("#anywhere-home").removeClass("bgshow");
        });
        $(document).on('click', '.onepage .mainmenu li a', function () {
          $("#side-bar").removeClass("show");
          $("#anywhere-home").removeClass("bgshow");
        });
      },
       // side menu desktop
       stickySidebar: function(e) {
        if ($("#sidebar").length) {
        var top = $('#sidebar').offset().top - parseFloat($('#sidebar').css('marginTop').replace(/auto/, 0));
        var footTop = $('#footer').offset().top - parseFloat($('#footer').css('marginTop').replace(/auto/, 0));

        var maxY = footTop - $('#sidebar').outerHeight();

        $(window).scroll(function(evt) {
          var y = $(this).scrollTop();
          if (y > top) {
            if (y < maxY) {
              $('#sidebar').addClass('fixed').removeAttr('style');
            } else {
              $('#sidebar').removeClass('fixed').css({
                position: 'absolute',
                top: (maxY - top) + 'px'
              });
            }
          } else {
            $('#sidebar').removeClass('fixed');
          }
        });
      }
      },
      // Search Bar show & hide
      searchOpton:function(){
        $(document).on('click', '.search-icon', function () {
          $(".search-input-area").addClass("show");
        });
        $(document).on('click', '.search-input-area input', function () {
          $(".search-input-area").addClass("show");
        });
        $(document).on('click', '.search-input-inner before', function () {
          $(".search-input-area").addClass("show");
        });
        $('html').click(function (e) {
          if (!$(e.target).hasClass('show')) {
            $(".search-input-area").removeClass("show");
          }
          $(document).on('click', '.search-close-icon', function () {
            $(".search-input-area").removeClass("show");
          });
        });
      },

  }

  imJs.m();

});

// $(window).on("scroll", function () {
//   var ScrollBarPostion = $(window).scrollTop();
//   console.log('ScrollBarPostion=========', ScrollBarPostion)
//   if (ScrollBarPostion > 300) {
//     $(".header-area").addClass("header-sticky");
//   } else {
//     $(".header-area").removeClass("header-sticky");
//     $(".header-area .header-top").removeClass("remove-content");
//   }
// });

$("#pageHeader").sticky({ topSpacing: 0 });
// $('#pageHeader').on('sticky-start', function () {
//   $(".header-area").addClass("header-sticky");
// });
// $('#pageHeader').on('sticky-end', function () {
//   $(".header-area").removeClass("header-sticky");
//   $(".header-area .header-top").removeClass("remove-content");
// });


// theme dark/light
var rts_light = $('.rts-dark-light');
if (rts_light.length) {
  var toggle = document.getElementById("rts-data-toggle");
  var storedTheme = localStorage.getItem('echo-theme') || (window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light");
  if (storedTheme)
    document.documentElement.setAttribute('data-theme', storedTheme)
  toggle.onclick = function () {
    var currentTheme = document.documentElement.getAttribute("data-theme");
    var targetTheme = "light";

    if (currentTheme === "light") {
      targetTheme = "dark";
    }
    document.documentElement.setAttribute('data-theme', targetTheme)
    localStorage.setItem('echo-theme', targetTheme);
  };
}

function smoothScrollto (id) {
  let target = document.querySelector(`#${id}`)
  target.scrollIntoView({
    behavior: 'smooth'
  });
}


